import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../coman_pages/Header";
import Footer from "../coman_pages/Footer";
// import carouselimg from "../Images/bannerCarousel.png";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/react-splide/css";
import savx from "../Images/SAVX.png";
import group11img from "../Images/Group11.png";
import group12img from "../Images/Group12.png";
import group13img from "../Images/Group13.png";
import group14img from "../Images/Group14.png";
import group15img from "../Images/Group15.png";
import group16img from "../Images/Group16.png";
import group17img from "../Images/Group17.png";
import Headerloop from "../Video/headervideo.mp4";
import Dry_logo from "../Images/DryGoods_Logo.png";
import { Helmet } from "react-helmet";

function Dashboard() {
  return (
    <>
      <Header />
      <div className="custommaargin video-containerhead">
        {/* <img className="img-fluid" src={carouselimg} alt="..." /> */}
        <video src={Headerloop} loop muted autoplay id="vid" />
        <div className="dashVideoContent">
          <img className="img-fluid goodslogo" src={Dry_logo} alt="My_Logo" />
          <div className="centerposition-head">
            <div className="d-flex justify-content-between">
              <div>BURN</div>
              <div>YOUR</div>
            </div>
            <div className="d-flex justify-content-between fSize-56 fontspacehead">
              <div>SAVANNA</div>
              <div className="nft">NFT</div>
            </div>
            <div className="customhr"></div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="leftsechead">
              WATERPROOF
              <br />
              APPAREL
              <br />
              FOR
            </div>
            <div className="Rightsechead">
              A<br />
              WORLD
              <br />
              UNDERWATER
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <script>document.getElementById('vid').play();</script>
      </Helmet>
      {/* <div>
        <Container fluid className="customcontainer">
          <div className="custommaargin customgray">
            <Row className="justify-content-between">
              <Col className="col-auto">
                <h1 className="fSize-60">HOW IT</h1>
              </Col>
              <Col className="col-auto">
                <h1 className="fSize-60">WORKS</h1>
              </Col>
            </Row>
            <div>
              <p>
                If you’re reading this we’ll assume that means you’re currently
                in possession of a Savanna Comedy Bar NFT. Dope.
              </p>
              <p>So, here’s what you need to know:</p>
              <p>
                This is your chance to enter our Dry Goods raffle. By clicking
                the link below and connecting your wallet, you’ll automatically
                be entered into a raffle. <br />
                The prize: your chance to “burn” your existing Savanna NFT in
                exchange for a Dry Goods voucher to the value of R2000.
              </p>
              <p>Only 20 winners will be chosen</p>
              <p>So, do you feel the burn?</p>
              <Link to="/there">
                <button className="loginbtn" disabled>BURN NFT</button>
              </Link>
            </div>
          </div>
        </Container>
      </div> */}
      <div className="mb-3 mt-5">
        <Splide
          className="splide"
          hasTrack={false}
          options={{
            type: "loop",
            rewind: true,
            arrows: false,
            pagination: false,
            gap: "0",
            perPage: 1,
            autoScroll: {
              speed: 1,
              pauseOnHover: false,
              pauseOnFocus: false,
              rewind: true,
            },
          }}
          extensions={{ AutoScroll }}
        >
          <SplideTrack>
            <SplideSlide>
              <img src={savx} className="img-fluid" alt="" title="" />
            </SplideSlide>
            <SplideSlide>
              <img src={savx} className="img-fluid" alt="" title="" />
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>
      <div>
        <Container fluid className="customcontainer">
          <div className="custommaargin customgray">
            <Row className="justify-content-between">
              <Col className="col-auto">
                <h1 className="fSize">WHAT’S UP FOR GRABS?</h1>
              </Col>
              {/* <Col className="col-auto">
                <h1 className="fSize"></h1>
              </Col> */}
            </Row>
            <div>
              <p>
                The winners of our Dry Goods Raffle will receive the opportunity
                to burn their existing NFT’s in exchange for a Dry Goods Voucher
                to the value of R2000. And just in case you don’t know by now –
                Dry Goods is a sustainably produced, <br />
                waterproof clothing range commissioned by Savanna and designed
                by the world-renowned Wanda Lephoto.
              </p>
              <p>
                Why waterproof? Because in a world of melting icebergs and
                rising sea levels, the water is coming whether you like it or
                not. So why not stay swagging?
              </p>
            </div>
          </div>
        </Container>
      </div>
      <Container fluid className="customcontainer">
        <div className="mb-3 mt-5">
          <Splide
            className="splide"
            hasTrack={false}
            options={{
              type: "loop",
              rewind: true,
              arrows: true,
              pagination: false,
              gap: "100px",
              perPage: 3,
              perSlide: 1,
            }}
          >
            <SplideTrack>
              <SplideSlide>
                <Nav.Link
                  href="https://wandalephoto.com/product/savanna-wanda-lephoto-ripstop-acacia-tree-camouflage-double-knee-pant-camouflage-pattern-printed-throughout/"
                  target="_blank"
                >
                  <img src={group11img} className="img-fluid" alt="" title="" />
                </Nav.Link>
              </SplideSlide>
              <SplideSlide>
                <Nav.Link
                  href="https://wandalephoto.com/product/savanna-wanda-lephoto-ripstop-acacia-tree-camouflage-cargo-skirt-camouflage-pattern-printed-throughout/"
                  target="_blank"
                >
                  <img src={group12img} className="img-fluid" alt="" title="" />
                </Nav.Link>
              </SplideSlide>

              <SplideSlide>
                <Nav.Link
                  href="https://wandalephoto.com/product/savanna-wanda-lephoto-ripstop-acacia-tree-camouflage-kimono-camouflage-pattern-printed-throughout/"
                  target="_blank"
                >
                  <img src={group13img} className="img-fluid" alt="" title="" />
                </Nav.Link>
              </SplideSlide>

              <SplideSlide>
                <Nav.Link
                  href="https://wandalephoto.com/product/savanna-wanda-lephoto-ripstop-acacia-tree-checkered-jacket/"
                  target="_blank"
                >
                  <img src={group14img} className="img-fluid" alt="" title="" />
                </Nav.Link>
              </SplideSlide>

              <SplideSlide>
                <Nav.Link
                  href="https://wandalephoto.com/product/savanna-wanda-lephoto-ripstop-acacia-tree-plaid-checkered-coat/"
                  target="_blank"
                >
                  <img src={group15img} className="img-fluid" alt="" title="" />
                </Nav.Link>
              </SplideSlide>

              <SplideSlide>
                <Nav.Link
                  href="https://wandalephoto.com/product/savanna-wanda-lephoto-ripstop-straight-leg-acacia-tree-checkered-pant/"
                  target="_blank"
                >
                  <img src={group16img} className="img-fluid" alt="" title="" />
                </Nav.Link>
              </SplideSlide>

              <SplideSlide>
                <Nav.Link
                  href="https://wandalephoto.com/product/savanna-wanda-lephoto-yellow-quilted-jacket-military-under-jacket/"
                  target="_blank"
                >
                  <img src={group17img} className="img-fluid" alt="" title="" />
                </Nav.Link>
              </SplideSlide>
            </SplideTrack>
            <div className="splide__arrows">
              <button className="splide__arrow splide__arrow--prev customarrowleft">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    width="40"
                    height="40"
                    focusable="false"
                  >
                    <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                  </svg>
                </span>
              </button>
              <button className="splide__arrow splide__arrow--next customarrowright">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  width="40"
                  height="40"
                  focusable="false"
                >
                  <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                </svg>
              </button>
            </div>
          </Splide>
        </div>
      </Container>
      <Footer />
    </>
  );
}
export default Dashboard;
