import React, { useState, useEffect,useRef, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../coman_pages/Header";
import { NavLink, useNavigate } from "react-router-dom";
import loopvideo from "../Video/squarevideo.mp4";
import Dry_logo from "../Images/DryGoods_Logo.png";
import { Helmet } from "react-helmet";
import { WalletContext } from "../context/Wallet";

function Luck() {
  const wallet = useContext(WalletContext);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const video = videoRef.current;
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
      }
    };
    handleVisibilityChange() 
  }, [loopvideo, videoRef.current, document.visibilityState ]);
  return (
    <>
    <Header />
     
          <div className="customgray customtop">
            <Row className="justify-content-between mx-0">
              <Col sm={12} md={6}>
              <div  className="d-flex position-relative video-container">               
                    <video loop muted autoplay src={loopvideo} id="vid" ref={videoRef}/>
                    <img className="img-fluid goodslogo"  src={Dry_logo} alt="My_Logo" style={{ position: 'absolute', top: 30, left: 30 }} />
                    <div className="leftposition">WATERPROOF<br />APPAREL<br />FOR</div>
                    <div className="rightposition">A<br />WORLD<br />UNDERWATER</div>
                </div>
                <Helmet>
                <script>
                    document.getElementById('vid').play();
                </script>
                </Helmet>
              </Col>
              <Col sm={12} md={6} className=" d-flex align-items-center justify-content-center py-5 py-lg-0">
                <div className="col-md-8 col-sm-10 px-3 py-md-0">
                <Row className="d-flex justify-content-between fSize-60 " style={{lineHeight:"60px"}}>
                <Col>BETTER <br />NEXT</Col>
                <Col>LUCK <br />TIME</Col>
              </Row>
              <div>
                <p>Ah man…</p>
                <p>Unfortunately, you do not own the necessary NFT to be able to participate in <br />this burn mechanic. If you wish to take part, you can pick up an NFT on the<br />Secondary market here:</p>
                 <NavLink to={"https://opensea.io/collection/savanna-nfts"} target="_blank">https://opensea.io/collection/savanna-nfts</NavLink>
                 
              </div>
              <div className="d-flex justify-content-between mt-3 flex-wrap">
              <button className="loginbtn text-nowrap mb-3" onClick={() => {
                wallet.disconnect()
                navigate('/goods');
              }}>DISCONNECT WALLET</button> 
              <NavLink to={"https://wandalephoto.com/store/" }target="_blank"> <button className="loginbtn text-nowrap mb-3">GET THE DRIP ANYWAY</button></NavLink>
             
              </div>
              </div>
              
              </Col>
            </Row>
          
          </div>
    </>
  );
}
export default Luck;