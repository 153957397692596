import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";

import Header from "../coman_pages/Header";
import loopvideo from "../Video/squarevideo.mp4";
import Dry_logo from "../Images/DryGoods_Logo.png";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API_URL } from "../constant";
import { WalletContext } from "../context/Wallet";
import cogoToast from "cogo-toast";

function Loading() {

  const [loading, setLoading] = useState(false);

  const videoRef = useRef(null);

  const {
    accountId,
    savannaContract,
    connectSavannaContract,
    getBalanceOf,
    web3Provider,
  } = useContext(WalletContext);

  const navigate = useNavigate();

  useEffect(() => {
    const video = videoRef.current;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
      }
    };
    handleVisibilityChange();
  }, [loopvideo, videoRef.current, document.visibilityState]);

  const burnNft = async () => {
    try {
      setLoading(true);
      const result = await axios.get(`${API_URL}burn/checkEligiblity/${accountId}`);

      if (result.data.data) {
        return cogoToast.error(
          "You have either not whitelisted or you have burned your nft"
        );
      }

      const nftContract = await connectSavannaContract();

      const nftId = await nftContract.methods
        .tokenOfOwnerByIndex(accountId, 0)
        .call();
      
      const fee = await web3Provider.eth.getGasPrice();
      let gasPrice = parseInt(fee);
    
      gasPrice = parseInt(gasPrice + (gasPrice * 1.5));

      await nftContract.methods
        .transferFrom(
          accountId,
          "0x000000000000000000000000000000000000dEaD",
          nftId
        )
        .send({ from: accountId, gasPrice });

      cogoToast.success("Nft has been burned successfully");

      await axios.get(`${API_URL}burn/updateStatus/${accountId}`);

      return navigate("/");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />

      <div className="customgray customtop">
        <Row className="justify-content-between mx-0">
          <Col sm={12} md={6}>
            <div className="d-flex position-relative video-container">
              <video
                loop
                muted
                autoplay
                src={loopvideo}
                id="vid"
                ref={videoRef}
              />
              <img
                className="img-fluid goodslogo"
                src={Dry_logo}
                alt="My_Logo"
                style={{ position: "absolute", top: 30, left: 30 }}
              />
              <div className="leftposition">
                WATERPROOF
                <br />
                APPAREL
                <br />
                FOR
              </div>
              <div className="rightposition">
                A<br />
                WORLD
                <br />
                UNDERWATER
              </div>
            </div>
            <Helmet>
              <script>document.getElementById('vid').play();</script>
            </Helmet>
          </Col>
          <Col
            sm={12}
            md={6}
            className=" d-flex align-items-center justify-content-center py-5 py-md-0"
          >
            <div className="col-md-8 col-sm-10 px-3 py-md-0">
              <Row className="d-flex justify-content-between fSize-60">
                <Col>DRIP</Col>
                <Col>LOADING...</Col>
              </Row>
              <div>
                <p>
                  Congratulations you’ve been chosen to burn your Comedy Bar
                  NFT!
                </p>
                <p>
                  Connect your wallet and then click burn and approve the
                  transaction in <br />
                  your metamask.
                </p>
              </div>
              <div className="d-flex justify-content-between col-12 flex-wrap">
                {/* <Link to="/secured"> */}
                <button
                  className="loginbtn text-nowwrap mb-3"
                  onClick={burnNft}
                  disabled = {loading}
                >
                  {
                    loading ? "Please Wait..." : "BURN NFT"
                  }
                </button>{" "}
                {/* </Link> */}
                <button className="loginbtn text-nowwrap mb-3">
                  DISCONNECT WALLET
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Loading;
