import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <hr className="footerline"></hr>
      <footer className=" py-3 footerline">
        <Container fluid className="footercontainer justify-content-between d-flex flex-wrap align-items-end"
        >
          <div className="mb-lg-0 customlist col-6 col-md-auto">  
          <ul>
            <li><NavLink to="/drip">BURN NFT</NavLink></li>
            <li>  <NavLink to={" "}>HOW TO BURN</NavLink></li>
          </ul>
          </div>

          <div className="customlist col-6 col-md-auto">
          <NavLink to={" "}>TERMS & CONDITIONS</NavLink>
          </div>

          <div className="text-align-right col-6 col-md-auto mt-5">
          {/* <NavLink className="loginbtn mb-5" to={'/'}>BACK TO TOP</NavLink>   */}
          <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className="loginbtn ">BACK TO TOP</button>
            <p className="mt-2 mb-0">©SAVANNACIDER 2023.</p>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;