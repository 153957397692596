import "../src/css/font.css"
import Router from "./Router/index"
import "bootstrap/dist/css/bootstrap.min.css"
import "../src/css/main.css"

function App() {
  return (
    <>
    <Router />
    </>
  );
}

export default App;
