import cogoToast from "cogo-toast";
import React, { Component, createContext } from "react";
import Web3 from "web3";
import savanaNFTABI from "../abis/savananft.json";

export const WalletContext = createContext();

const CONTRACT_ADDRESS = "0xE66ea0a04808E65d6c6Ede699F7FcE29d797D346"
// const CONTRACT_ADDRESS = "0x918b7248dc7049E292f4211dFf1990E744336Af1";

export class WalletContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      web3Provider: null,
      accountId: null,
      chainId: 137,
      // chainId: 80001,
      balance: 0,
      savannaContract: null,
    };
  }

  componentDidMount() {
    if (window && window.ethereum) {
      window.ethereum.on("chainChanged", async (networkId) => {
        console.log("chainChanged", networkId);
        const web3 = new Web3(window.ethereum);
        let chainId = web3.utils.toHex(this.state.chainId);
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }], // chainId must be in hexadecimal numbers
        });
        this.connectWallet();
      });
    }
  }

  connectWallet = async () => {
    if (window && window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        // await window?.ethereum.request({
        //   method: "wallet_requestPermissions",
        //   params: [
        //     {
        //       eth_accounts: {},
        //     },
        //   ],
        // });
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const accountID = accounts[0];
        web3.eth.defaultAccount = accounts[0];
        const networkId = await web3.eth.net.getId();

        if (parseInt(networkId) === this.state.chainId) {
          this.setState({
            ...this.state,
            web3Provider: web3,
            accountId: accountID,
          });

          return accountID;
        } else {
          // if(parseInt(networkId) !== parseInt(connectdata.chainId)) {
          let chainId = web3.utils.toHex(parseInt(this.state.chainId));
          await window?.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId }], // chainId must be in hexadecimal numbers
          });
          // }

          this.connectWallet();
          // this.setState({ connectModal: false });
          // return cogoToast.error(
          //   `Please change your network to ${networkId !== connectdata.chainId && connectdata.chain_name }`
          // );
        }
      } catch (err) {
        console.log(err);
        if (
          err.code == 4902 ||
          err.code == 32603 ||
          err.code == -32603 ||
          err?.data?.originalError?.code == 4902
        ) {
          try {
            let chainId = web3.utils.toHex(
              parseInt(this.state && this.state.chainId)
            );
            cogoToast.error(`Please change your network to Polygon Mainnet.`);
            await window?.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: chainId,
                  // rpcUrls: ["https://polygon-mainnet.infura.io"],
                  rpcUrls: ["https://polygon-rpc.com/"],
                  chainName: "Polygon Mumbai",
                  nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18,
                  },
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
            cogoToast.error(
              addError.message ? addError.message.toString() : addError
            );
          }
        }
      }
    } else if (window && window.web3) {
      const web3 = new Web3(
        window && window.web3 && window.web3.currentProvider
      );
      this.setState({ ...this.state, web3Provider: web3 });
    } else {
      cogoToast.error("You have to install MetaMask !");
    }
  };

  connectSavannaContract = async () => {
    try {
      let web3;
      if (!this.state.web3Provider) {
        web3 = new Web3(window && window.ethereum);
      } else {
        web3 = new Web3(this.state.web3Provider);
      }
      const savanaNFT = await new web3.eth.Contract(
        savanaNFTABI,
        CONTRACT_ADDRESS
      );
      this.setState({
        savannaContract: savanaNFT,
      });

      return savanaNFT;
    } catch (error) {}
  };

  getBalanceOf = async (address) => {
    let web3;
    if (!this.state.web3Provider) {
      web3 = new Web3(window && window.ethereum);
    } else {
      web3 = new Web3(this.state.web3Provider);
    }
    const savanaNFT = await new web3.eth.Contract(
      savanaNFTABI,
      CONTRACT_ADDRESS
    );
    const balance = await savanaNFT.methods.balanceOf(address).call();
    this.setState({
      ...this.state,
      balance: balance,
    });
    return balance;
  };

  disconnect = async () => {
    this.setState({
      web3Provider: null,
      accountId: null,
    });
  };

  render() {
    const { children } = this.props;
    return (
      <WalletContext.Provider value={{ ...this, ...this.state }}>
        {children}
      </WalletContext.Provider>
    );
  }
}

export default WalletContextProvider;
