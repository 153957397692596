import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import groupimg from "../Images/Group.png";
import Header from "../coman_pages/Header";
import loopvideo from "../Video/squarevideo.mp4";
import Dry_logo from "../Images/DryGoods_Logo.png";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import { useContext } from "react";
import { WalletContext } from "../context/Wallet";
import axios from "axios";
import { API_URL } from "../constant";
import cogoToast from "cogo-toast";

function Goods() {
  const wallet = useContext(WalletContext);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  useEffect(() => {
    const video = videoRef.current;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
      }
    };
    handleVisibilityChange();
  }, [loopvideo, videoRef.current, document.visibilityState]);
  useEffect(() => {
    if (!wallet?.accountId) {
      navigate("/there");
    }
  }, [wallet.accountId]);
  return (
    <>
      <Header />

      <div className="customgray customtop">
        <Row className="justify-content-between mx-0">
          <Col sm={12} lg={6}>
            <div className="d-flex position-relative video-container">
              <video
                loop
                muted
                autoplay
                src={loopvideo}
                id="vid"
                ref={videoRef}
              />
              <img
                className="img-fluid goodslogo"
                src={Dry_logo}
                alt="My_Logo"
                style={{ position: "absolute", top: 30, left: 30 }}
              />
              <div className="leftposition">
                WATERPROOF
                <br />
                APPAREL
                <br />
                FOR
              </div>
              <div className="rightposition">
                A<br />
                WORLD
                <br />
                UNDERWATER
              </div>
            </div>
            <Helmet>
              <script>document.getElementById('vid').play();</script>
            </Helmet>
          </Col>
          <Col
            sm={12}
            lg={6}
            className=" d-flex align-items-center justify-content-center py-5 py-lg-0"
          >
            <div className="col-md-10 px-3 py-lg-0">
              <div
                className="d-flex justify-content-between fSize-60"
                style={{ lineHeight: "60px" }}
              >
                <div>
                  YOU <br />
                  THE
                </div>
                <div>
                  GOT <br />
                  GOODS
                </div>
              </div>
              <div className="mt-3">
                <p>
                  Not everyone has the right stuff. But you do. You’re NFT is
                  ready
                  <br />
                  for our burn mechanic. Kindly fill out the form below and
                  you’ll be
                  <br />
                  entered into our raffle.
                </p>
              </div>
              <Formik
                initialValues={{ email: "", name: "", mobile: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.email = "Required";
                  }
                  if (!values.mobile) {
                    errors.email = "Required";
                  }
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  console.log(values);
                  axios
                    .post(`${API_URL}burn-raffles/add`, {
                      email: values.email,
                      name: values.name,
                      mobile: values.mobile,
                      walletAddress: wallet.accountId,
                    })
                    .then((success) => {
                      setSubmitting(false);
                      navigate("/congratulation");
                    })
                    .catch((error) => {
                      cogoToast.error("Please try again later.");
                    });
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  submitForm,
                }) => (
                  <>
                    <div class="login-box">
                      <form>
                        <div class="user-box">
                          <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            placeholder="NAME & SURNAME"
                          />
                          {errors.name && (
                            <span className="text-danger">{errors.name}</span>
                          )}
                        </div>
                        <div class="user-box">
                          <input
                            type="text"
                            name="mobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile}
                            placeholder="PHONE"
                          />
                          {errors.mobile && (
                            <span className="text-danger">{errors.mobile}</span>
                          )}
                        </div>
                        <div class="user-box">
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="EMAIL"
                          />
                          {errors.email && (
                            <span className="text-danger">{errors.email}</span>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="d-flex justify-content-between flex- wrap mt-3">
                      {/* <Link to='/drip'><button className="loginbtn text-nowrap mb-3 me-5">ENTER BURN RAFFLE</button></Link> */}
                      {/* <Link to='/congratulation'> */}
                      <button
                        disabled={isSubmitting}
                        className="loginbtn text-nowrap mb-3 me-5"
                        onClick={submitForm}
                      >
                        NFT BURN
                      </button>
                      {/* </Link> */}
                      <button
                        className="loginbtn text-nowrap mb-3"
                        type="button"
                        onClick={() => {
                          wallet.disconnect();
                          navigate("/goods");
                        }}
                      >
                        DISCONNECT WALLET
                      </button>
                    </div>
                  </>
                )}
              </Formik>
              <div></div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Goods;
