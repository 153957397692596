import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Groupnft from "../Images/Groupnft.png";
import Header from "../coman_pages/Header";
import goodsimg from "../Images/Goods.png";

function Secured() {
  return (
    <>
      <Header />
      <div className="customgray customtop">
        <Row className="justify-content-between mx-0">
          <Col sm={12} lg={6}>
            <div className="d-flex position-relative">
              <img className="img-fluid" src={goodsimg} />
              <div className="leftposition">
                WATERPROOF
                <br />
                APPAREL
                <br />
                FOR
              </div>
              <div className="rightposition">
                A<br />
                WORLD
                <br />
                UNDERWATER
              </div>
              <div className="centerposition">
                <div className="d-flex justify-content-between">
                  <div>SAVANNA</div>
                  <div>WANDA LEPHOTO</div>
                </div>
                <div className="d-flex justify-content-between fSize-56 fontspace"> 
                <div>DRY</div>
                <div>GOODS</div>
                </div>
                <div className="customhr"></div>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6} className=" d-flex align-items-center justify-content-center py-5 py-lg-0">
            <div className="col-md-10  px-3 py-lg-0 ">
              <Row className="d-flex justify-content-between fSize-60">
                <Col>DRIP</Col>
                <Col>SECURED</Col>
              </Row>
             
              <div>
                <p>Congratulations, you’ve successfully burnt your NFT!</p>
                <p>We’ll contact you about your rewards soon!</p>
                <p>You may now disconnect your wallet.</p>
              </div>
              <div>
                <button className="loginbtn">DISCONNECT WALLET</button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Secured;
