import { Container, Nav, Navbar, NavDropdown, NavLink,Carousel} from 'react-bootstrap';
import logo from "../Images/logo.png";
import { Link } from 'react-router-dom';

function Header() {
    return (
        
   <>
      <Navbar className='customheader' expand="lg" fixed="top">
        <Container fluid className='footercontainer'>
        <Navbar.Brand href="/" className="customlogo"><img src={logo} alt="" /></Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/* <NavLink href="#" className="customnav me-2">Products</NavLink>
            <NavLink href="#" className="customnav me-2">Services</NavLink>
            <NavLink href="#" className="customnav me-2 ">Services</NavLink>
            <NavLink href="#" className="customnav me-2">Solutions</NavLink>
            <NavLink href="#" className="customnav me-2">Pricing</NavLink>
            <NavLink href="#" className="customnav me-2">About us</NavLink>
            <NavLink href="#" className="customnav me-2">Contact us</NavLink> */}
            {/* <Link to='/dashboard'><button className="loginbtn me-5">DASHBOARD</button></Link> */}
           <Link to='/drip'><button className="loginbtn">BURN NFT</button></Link>  
          </Nav>
        </Navbar.Collapse>

        </Container>
      </Navbar>

      
   </>   
    );
}

export default Header;