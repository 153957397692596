import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../coman_pages/Header";
import { Link } from "react-router-dom";
import loopvideo from "../Video/squarevideo.mp4";
import Dry_logo from "../Images/DryGoods_Logo.png";
import { Helmet } from "react-helmet";
import { WalletContext } from "../context/Wallet";

function Drip() {
  const videoRef = useRef(null);

  const { connectWallet } = useContext(WalletContext);


  useEffect(() => {
    const video = videoRef.current;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
      }
    };
    handleVisibilityChange();
  }, [loopvideo, videoRef.current, document.visibilityState]);

  return (
    <>
      <Header />

      <div className="customgray customtop">
        <Row className="justify-content-between mx-0">
          <Col sm={12} md={6}>
            <div className="d-flex position-relative video-container">
              <video
                loop
                muted
                autoplay
                src={loopvideo}
                id="vid"
                ref={videoRef}
              />
              <img
                className="img-fluid goodslogo"
                src={Dry_logo}
                alt="My_Logo"
                style={{ position: "absolute", top: 30, left: 30 }}
              />
              <div className="leftposition">
                WATERPROOF
                <br />
                APPAREL
                <br />
                FOR
              </div>
              <div className="rightposition">
                A<br />
                WORLD
                <br />
                UNDERWATER
              </div>
            </div>
            <Helmet>
              <script>document.getElementById('vid').play();</script>
            </Helmet>
          </Col>
          <Col
            sm={12}
            md={6}
            className=" d-flex align-items-center justify-content-center py-5 py-md-0"
          >
            <div className="col-md-8 col-sm-10 px-3 px-md-0">
              <Row className="d-flex justify-content-between fSize-60">
                <Col>DRIP</Col>
                <Col>LOADING...</Col>
              </Row>
              <div>
                <p>
                  Congratulations you’ve been chosen to burn your Comedy Bar
                  NFT!
                </p>
                <p>
                  Connect your wallet and then click burn and approve the
                  transaction in <br />
                  your metamask.
                </p>
              </div>
              <div>
                <Link to="/loading">
                  <button className="loginbtn text-nowrap" onClick={() => connectWallet()}>
                    CONNECT TO WALLET
                  </button>{" "}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default Drip;
