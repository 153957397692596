import routes from "./router";
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const RoutePage = () =>{
    return (
        <Router>
    
            <Routes>
                {routes.map(({ path, auth, component: Component }, key) => {
                    return (
                       
                            <Route exact path={path} element={Component} /> 
                    )
                })
                }
            </Routes>
        </Router>
    )
}

export default RoutePage;