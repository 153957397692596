import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
// import groupimg from "../Images/Group.png";
import Header from "../coman_pages/Header";
import loopvideo from "../Video/squarevideo.mp4";
import Dry_logo from "../Images/DryGoods_Logo.png";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { WalletContext } from "../context/Wallet";


function There() {
  const { connectWallet, getBalanceOf} = useContext(WalletContext);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const video = videoRef.current;
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        video.currentTime = 0;
        video.play();
      } else {
        video.pause();
      }
    };
    handleVisibilityChange()
  }, [loopvideo, videoRef.current, document.visibilityState]);

  return (
    <>
      <Header />

      <div className="customgray customtop">
        <Row className="justify-content-between mx-0">
          <Col sm={12} md={6}>
            <div className="d-flex position-relative video-container">

              <video loop muted autoplay src={loopvideo} id="vid" ref={videoRef} />
              <img className="img-fluid goodslogo" src={Dry_logo} alt="My_Logo" style={{ position: 'absolute', top: 30, left: 30 }} />
              <div className="leftposition">WATERPROOF<br />APPAREL<br />FOR</div>
              <div className="rightposition">A<br />WORLD<br />UNDERWATER</div>
            </div>
            <Helmet>
              <script>
                document.getElementById('vid').play();
              </script>
            </Helmet>
          </Col>
          <Col sm={12} md={6} className=" d-flex align-items-center justify-content-center py-5 py-md-0">
            <div className="col-md-8 col-sm-10 px-3 py-md-0">
              <Row className="d-flex justify-content-between fSize-60">
                <Col>ALMOST</Col>
                <Col>THERE</Col>
              </Row>
              <div><p>Thank you for taking the next step to entering our raffle and burning your <br />Comedy Bar NFT.</p>
                <p>To complete your entry, please connect your wallet by clicking below, in order <br />to confirm that you have the relevant NFT.</p>
              </div>
              <div>
                  <button className="loginbtn" onClick={async () => {
                    try {
                      const accountId = await connectWallet();
                      if (accountId) {
                        const balance = await getBalanceOf(accountId);
                        if (balance > 0) {
                          navigate('/goods');
                        } else {
                          // navigate('/goods');
                          navigate('/luck');
                        }
                      }
                    } catch (error) {
                      console.log("error", error);
                    }
                  }}>CONNECT WALLET</button>
              </div>
            </div>

          </Col>
        </Row>

      </div>
    </>
  );
}
export default There;