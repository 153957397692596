import Dashboard from "../Pages/Dashboard";
import Drip from "../Pages/drip";
import Secured from "../Pages/secured";
import Loading from "../Pages/loading";
import There from "../Pages/there";
import Luck from "../Pages/luck";
import Goods from "../Pages/goods";
import Congratulation from "../Pages/Congratulation";
import Datatable from '../Pages/Datatable';

const routes = [
    {   
        path: "/dashboard",
          exact: true,
          auth: false,
          component: <Datatable title="Datatable" />,
      }, 
     
      {   
        path: "/congratulation",
          exact: true,
          auth: false,
          component: <Congratulation title="Congratulation" />,
      }, 
    {   
        path: "/",
          exact: true,
          auth: false,
          component: <Dashboard title="Dashboard" />,
      },   
      {
        path: "/drip",
        exact: true,
        auth: false,
        component: <Drip title="Drip" />,
    },   
    {
        path: "/secured",
        exact: true,
        auth: false,
        component: <Secured title="Secured" />,
    }, 
    {
        path: "/loading",
        exact: true,
        auth: false,
        component: <Loading title="Loading" />,
    }, 
    {
        path: "/there",
        exact: true,
        auth: false,
        component: <There title="There" />,
    }, 
    {
        path: "/luck",
        exact: true,
        auth: false,
        component: <Luck title="Luck" />,
    }, 
    {
        path: "/goods",
        exact: true,
        auth: false,
        component: <Goods title="Goods" />,
    }, 
      
  ];
  export default routes;